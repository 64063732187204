<template>
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">{{t('10030')}}</i>
			</a>
			<a href="#" class="nav__btn nav__btn--map">
				<!--<i class="icon icon-share--white"></i>-->
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub detail">
			<!-- gallery -->
			<section class="gallery">
				<div class="gallery__slider gallery__slider--noinfo">
					<swiper @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1">
						<swiper-slide>
							<img src="@/assets/images/templeisure02.jpg" alt="역삼벤자민모텔">
						</swiper-slide>
						<swiper-slide>
							<img src="@/assets/images/templeisure02.jpg" alt="역삼벤자민모텔">
						</swiper-slide>
					</swiper>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="detail__wrap">
						<!-- 상품 기본정보 -->
						<header class="detail__header">
							<div class="detail__title">
								<div class="detail__slogan">
									{{t('10146')}}
								</div>
								<h2 class="title">{{t('10147')}}</h2>
							</div>
							<div class="detail__contact">
								<div class="detail__grad info-grad">
									<span class="list__icon info-icon">
										<i class="icon icon-star"></i>
									</span>
									<span class="list__gradnum info-gradnum">
										9.7
									</span>
									<span class="list__gradcounter info-gradcounter">
										(2,400)
									</span>
								</div>
								<div class="detail__pickcount">
                                    <i class="icon icon-pick icon-pick--point"></i>
                                    <span class="skyblue">17</span>명이 픽한 상품입니다 <!-- 재번역 체크 -->
								</div>
							</div>
						</header>
						<!-- 상품 상세정보 -->
						<div class="detail__body">
							<!-- 숙소 정보 -->
							<article class="detail__meta">
								<div class="detail__expired">
                                    유효기간 : 구매일로부터 <strong>60</strong>일 <!-- 재번역 체크 -->
								</div>
                                <div class="detail__conditional">
									{{t('10150')}}
                                </div>
							</article>
							<!-- 상품 정보 이미지 -->
							<article class="detail__image">
                                <h3 class="subtitle">{{t('10151')}}</h3>
                                <div class="detail__image-wrap">
                                    <img src="@/assets/images/templeisure-detail.jpg" alt="숙소 상세 정보">
                                </div>
							</article>
							<!-- 상품 상세정보 -->
							<article class="detail__info">
                                <h3 class="subtitle">
                                    {{t('10152')}}
                                </h3>
								<!-- 상품 사용 안내 -->
								<div class="detail__info-item">
                                    <h4 class="detail__subtitle">
                                        {{t('10153')}}
                                    </h4>
									<p class="detail__description description">
										{{t('10154')}}
									</p>
								</div>
								<!-- 유의 사항 안내 -->
								<div class="detail__info-item">
									<h4 class="detail__subtitle">
										{{t('10155')}}
									</h4>
									<p class="detail__description description">
										{{t('10156')}}
									</p>
								</div>
							</article>
							<!-- 상품 맵 -->
							<article class="detail__map">
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.7105605879824!2d127.02890815123945!3d37.51474417970842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3efae026a89%3A0xec9adecd181c22c9!2z67mE64u37YGs66as7JeQ7J207Yuw67iMKOyjvCk!5e0!3m2!1sko!2skr!4v1636532660072!5m2!1sko!2skr"
								        style="border:0;" allowfullscreen="" loading="lazy"></iframe>
							</article>
							<!-- 구매후기 -->
							<article class="detail__review">
								<h3 class="title subtitle">
									{{t('10157')}}
									<small>(2,700)</small>
									<a href="#" class="btn-more">
										MORE
									</a>
								</h3>
								<div class="reviews">
									<!-- 리뷰 1 -->
									<div class="review__item">
										<div class="review__body">
											<div class="flex">
												<div class="review__type">
													{{t('10158')}}
												</div>
												<div class="review__name">
													{{t('10159')}}
												</div>
											</div>
											<div class="flex">
												<div class="review__grad">
													<i class="icon icon-star"></i>
													<i class="icon icon-star"></i>
													<i class="icon icon-star"></i>
													<i class="icon icon-star"></i>
													<i class="icon icon-star--light"></i>
												</div>
												<div class="review__date">
													2021.09.28
												</div>
											</div>
											<div class="review__image">
												<img src="@/assets/images/temp09.jpg" alt="역삼 벤">
											</div>
											<div class="review__desc">
												{{t('10160')}}
											</div>
										</div>
									</div>
									<!-- 리뷰 2 -->
									<div class="review__item">
										<div class="review__body">
											<div class="flex">
												<div class="review__type">
													{{t('10158')}}
												</div>
												<div class="review__name">
													{{t('10159')}}
												</div>
											</div>
											<div class="flex">
												<div class="review__grad">
													<i class="icon icon-star"></i>
													<i class="icon icon-star"></i>
													<i class="icon icon-star"></i>
													<i class="icon icon-star"></i>
													<i class="icon icon-star--light"></i>
												</div>
												<div class="review__date">
													2021.09.28
												</div>
											</div>
											<div class="review__desc">
												{{t('10160')}}
											</div>
										</div>
									</div>
								</div>
							</article>
                            <!-- 가까운 레저티켓 -->
							<article class="detail__related">
								<h3 class="subtitle">{{t('10161')}}</h3>
								<h4 class="title detail__subtitle">
									{{t('10162')}}
									<a href="#" class="btn-more">
										MORE
									</a>
								</h4>
								<div class="carousel">
									<div class="carousel carousel--left carousel--small">
										<swiper :slides-per-view="1.4" :space-between="16">
											<swiper-slide>
												<a href="#" class="carousel__link">
													<div class="carousel__image">
														<img src="@/assets/images/tempsquare03.jpg" alt="역삼 벤">
													</div>
													<div class="carousel__info">
														<h3 class="carousel__title info-subtitle">
															{{t('10163')}}
														</h3>
														<div class="carousel__price">
																<span class="list__badge list__badge--strong">
																	{{t('10164')}}
																</span>
															<span class="carousel__saleprice">
																	28%
																</span>
															<span class="carousel__currentprice">
																	<strong>49,800</strong>{{t('10088')}}
																</span>
														</div>
													</div>
												</a>
											</swiper-slide>
											<swiper-slide>
												<a href="#" class="carousel__link">
													<div class="carousel__image">
														<img src="@/assets/images/tempsquare03.jpg" alt="역삼 벤">
													</div>
													<div class="carousel__info">
														<h3 class="carousel__title info-subtitle">
															{{t('10163')}}
														</h3>
														<div class="carousel__price">
															<span class="list__badge list__badge--strong">
																{{t('10164')}}
															</span>
															<span class="carousel__saleprice">
																28%
															</span>
															<span class="carousel__currentprice">
																<strong>49,800</strong>{{t('10088')}}
															</span>
														</div>
													</div>
												</a>
											</swiper-slide>
										</swiper>
									</div>
								</div>
							</article>
							<!-- 가까운 숙소 추천 -->
							<article class="detail__related">
								<h3 class="title detail__subtitle">
									{{t('10165')}}
									<a href="#" class="btn-more">
										MORE
									</a>
								</h3>
								<!-- [REST] 같은 동 상품 노출 -->
								<div class="carousel">
									<!-- [REST] 같은 동 상품 노출 -->
									<div class="carousel__slider">
										<swiper :slides-per-view="1.4" :space-between="16">
											<swiper-slide>
												<a href="#" class="carousel__link">
													<div class="carousel__image">
														<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
													</div>
													<div class="carousel__info">
														<h3 class="carousel__title info-title">
															{{t('10166')}}
														</h3>
														<div class="carousel__grad info-grad">
															<span class="carousel__icon info-icon">
																<i class="icon icon-star"></i>
															</span>
															<span class="carousel__gradnum info-gradnum">
																9.7
															</span>
															<span class="carousel__gradcounter info-gradcounter">
																(2,400)
															</span>
														</div>
														<div class="carousel__price">
															<span class="list__badge list__badge--strong">
																{{t('10164')}}
															</span>
															<span class="carousel__saleprice">
																28%
															</span>
															<span class="carousel__currentprice">
																<strong>49,800</strong>{{t('10088')}}
															</span>
														</div>
													</div>
												</a>
											</swiper-slide>
											<swiper-slide>
												<a href="#" class="carousel__link">
													<div class="carousel__image">
														<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
													</div>
													<div class="carousel__info">
														<h3 class="carousel__title info-title">
															{{t('10167')}}
														</h3>
														<div class="carousel__grad info-grad">
															<span class="carousel__icon info-icon">
																<i class="icon icon-star"></i>
															</span>
															<span class="carousel__gradnum info-gradnum">
																9.7
															</span>
															<span class="carousel__gradcounter info-gradcounter">
																(2,400)
															</span>
														</div>
														<div class="carousel__price">
															<span class="list__badge list__badge--strong">
																{{t('10164')}}
															</span>
															<span class="carousel__saleprice">
																28%
															</span>
															<span class="carousel__currentprice">
																<strong>49,800</strong>{{t('10088')}}
															</span>
														</div>
													</div>
												</a>
											</swiper-slide>
										</swiper>
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<!-- navigation -->
	<div id="navigation" class="navigation">
		<div class="navigation-button">
			<a class="btn btn-confirm">
				{{t('10168')}}
			</a>
			<ul class="item__menu">
				<li>
					<a href="#">
						<i class="icon icon-pick">{{t('10015')}}</i>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<!-- //navigation -->
	<TheNavigation></TheNavigation>
</template>

<script>
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	methods : {
		/* 현재 슬라이드 넘버 지정하기 */
		setSlideCurrentIndex : (swiper) => {
			var target = swiper.$el[0].getAttribute('data-currentnum'),
				index = swiper.activeIndex;
			document.querySelector(target).innerHTML = index + 1;
		}
	},
	setup() {
		const { t }= useI18n() //번역필수 모듈
	    return {
			t,
			i18n,
	}
	},
}
</script>